body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #000;
}

#loader-app {
    height: 50px;
    width: 50px;
    margin: 0 auto 15px auto;
    animation: spin infinite 5s linear;
}

#scrollList {
    
}

#root > div form{
    padding-bottom: 30px !important;
}

html,
body {
    overflow: hidden;
    color: white;
    font-family: "Roboto", sans-serif;
     background:
        radial-gradient(circle at 6.98% 8.03%, rgb(55, 55, 75), transparent 59%),
        radial-gradient(circle at 35.99% 4.01%, rgb(83, 80, 151), transparent 51%),
        radial-gradient(circle at 40.99% 48.05%, rgb(64, 63, 90), transparent 27%),
        radial-gradient(circle at 70% 80.99%, rgb(67, 94, 100), transparent 48%),
        radial-gradient(circle at 64.01% 78.99%, rgb(74, 42, 88), transparent 62%),
        radial-gradient(circle at 50% 50%, rgb(90, 47, 75), rgb(38, 37, 45) 100%);
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
}

.App {
    width: 100%;
    height: 100%;
    position: relative;
    transition: background 1.5s linear;
}

.AppWrapper {
    width: 100%;
    height: 100%;
}

#root {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
    z-index: 2;
}

*,
::after,
::before {
    box-sizing: inherit;
}

@media (min-width: 1024px) and (orientation: landscape) {
    body {
        position: fixed;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
