body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #000;
}

#loader-app {
    height: 50px;
    width: 50px;
    margin: 0 auto 15px auto;
    animation: spin infinite 5s linear;
}

#scrollList {
    
}

#root > div form{
    padding-bottom: 30px !important;
}
